body{
    box-sizing: content-box;
    line-height: normal;
    background-color: #f4f4f4;
}
select{
    box-sizing: border-box;
}
.srch-tab-line .custom-select{
    -webkit-appearance: listbox;
}
