// Custom.scss
// Option A: Include all of Bootstrap

$body-bg: #f4f4f4;

$btn-border-radius: 0.15rem;
$btn-font-family: 'Montserrat';

.btn-semi-block{
    width: 90px;
}

$theme-colors: (
  "rihlat": #FF7200
);

@import "../../node_modules/bootstrap/scss/bootstrap";





// // Custom.scss
// // Option B: Include parts of Bootstrap

// // Required
// @import "../../node_modules/bootstrap/scss/functions";
// @import "../../node_modules/bootstrap/scss/variables";
// @import "../../node_modules/bootstrap/scss/mixins";

// // Optional
// @import "../node_modules/bootstrap/scss/reboot";
// @import "../node_modules/bootstrap/scss/type";
// @import "../node_modules/bootstrap/scss/images";
// @import "../node_modules/bootstrap/scss/code";
// @import "../node_modules/bootstrap/scss/grid";